import React from 'react'
import styled from 'styled-components'

import Header from './Header'
import Footer from './Footer'

type ComponentProps = {
  children?: React.ReactNode
}

const Main = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default function PageWrapper(props: ComponentProps) {
  const { children } = props

  return <Main>
    <Header />
    <>{ children }</>
    <Footer />
  </Main>
}

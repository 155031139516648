import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import usePageHead from '../hooks/usePageHead'

import { ReactComponent as AppIconSvg } from '../images/app-icon.svg'

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NoBr = styled.span`
  white-space: nowrap;
`

export default function ComingSoon() {
  const { updatePageHead } = usePageHead()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    updatePageHead({
      title: 'Coming Soon',
      description: 'The Fewlsy website is coming soon.'
    })
  }, [updatePageHead])

  return <Container>
    <Box mb={4} textAlign="center">
      <AppIconSvg width={128} height={128} />
    </Box>
    <Box mb={1} textAlign="center">
      <Typography variant={mdUp ? 'h3' : 'h4'} component="h1">Coming Soon</Typography>
    </Box>
    <Box textAlign="center">
      <Typography color="GrayText" variant={mdUp ? 'h5' : 'h6'} component="p" lineHeight={1.3}>The Fewlsy website <NoBr>is coming soon</NoBr></Typography>
    </Box>
  </Container>
}

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ReactComponent as AppIconMutedSvg } from '../images/app-icon-muted.svg'

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: #f9f9fa;
`;

const FooterGraphic = styled.div`
  background-image: linear-gradient(to right, #12ab52, #fff312);
  height: 10px;
`

const FooterNav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
`
const FooterNavLink = styled(Link)`
  padding: 0;
  margin: 0;
  border: 0;
  padding: 0.5rem 0.75rem;
  margin-left: 1rem;
  text-decoration: none;
  display: inline-block;
  color: #333333;
  font-size: 1rem;
  border-top: solid 3px transparent;
  border-bottom: solid 3px transparent;
  transition: all 150ms linear;
  :hover {
    color: #2979ff;
    border-bottom-color: #2979ff;  
  }
  :first-child {
    margin-left: 0;
  }
`

export default function Footer() {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  return <FooterWrapper>
    <FooterGraphic />
    <Box px={2} py={4}>
      <Box mb={3} textAlign={mdUp ? 'center' : 'left'} maxWidth={mdUp ? 580 : 'none'} mx={mdUp ? 'auto' : 0}>
        <Box mb={2}>
          <AppIconMutedSvg width={mdUp ? 64 : 32} height={mdUp ? 64 : 32} />
        </Box>
        <Box mb={1}>
          <Typography variant="h5" component="h2">About us</Typography>
        </Box>
        <Box>
          <Typography variant="body1">We are a small startup based in Davao City, Philippines. Our mission — to empower the lives of vehicle owners with cutting-edge technology.</Typography>
        </Box>
      </Box>
      <>
        {mdUp &&
          <FooterNav>
            <FooterNavLink to="/#todo">Terms and Conditions</FooterNavLink>
            <FooterNavLink to="/#todo">Privacy Policy</FooterNavLink>
          </FooterNav>
        }
      </>
      <Box textAlign={mdUp ? 'center' : 'left'}>
        <Typography color="GrayText">© 2022 Fewlsy. All rights reserved.</Typography>
      </Box>
    </Box>
  </FooterWrapper>
}

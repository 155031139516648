import React from 'react'
import ReactDOM from "react-dom/client"
import App from './App'
import reportWebVitals from './reportWebVitals'

console.log(`fewlsy-website-web@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_ENV}`)

const rootElement = document.getElementById('root')
if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, <App />)
} else if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)

  if (process.env.REACT_APP_USE_STRICT_MODE) {
    root.render(<React.StrictMode><App /></React.StrictMode>)
  } else {
    root.render(<App />)
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

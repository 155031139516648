import React, { useEffect } from 'react'
import PageWrapper from '../components/PageWrapper'

import styled from 'styled-components'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import usePageHead from '../hooks/usePageHead'

import BusinessPowerUpImg from '../images/business-power-up.jpg'
import BusinessLeadGenerationImg from '../images/business-lead-generation.jpg'
import BusinessCustomerExperienceImg from '../images/business-customer-experience.jpg'
import BusinessInsightsImg from '../images/business-insights.jpg'

const InfoDescription = styled.p`
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  color: #777777;
  font-size: 1.15rem;
  line-height: 1.75rem;
`

const SignUpButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.2375rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 12px 40px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
  background-color: #2979ff;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  :hover {
    text-decoration: none;
    background-color: #1c54b2;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`

export default function BusinessPage() {
  const { updatePageHead } = usePageHead()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    updatePageHead({
      title: 'Power-up your gas station business with Fewlsy Business',
      description: 'Fewlsy Business helps expand your reach, boost sales, and more'
    })

    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
     });
  }, [updatePageHead])

  return <PageWrapper>
    <Container component="section">
      <Box minHeight={mdUp ? '75vh' : '50vh'} display="flex" flexDirection="row" justifyContent="center" alignItems="center" py={mdUp ? 10 : 4}>
        <Box textAlign="center">
          <Box mb={mdUp ? 4 : 1}>
            <img src={BusinessPowerUpImg} width={mdUp ? 600 : 300} height={mdUp ? 400 : 200} alt="Power-up your gas station and apply for Fewlsy Business" />
          </Box>
          <Box mb={mdUp ? 4 : 1}>
            <Typography component="h1" variant={mdUp ? 'h3' : 'h4'}>Power-up your gas station business</Typography>
          </Box>
          <Box mb={4}>
            <Typography component="p" variant={mdUp ? 'h5' : 'body1'}>Fewlsy Business helps expand your reach, boost sales, and more</Typography>
          </Box>
          <Box>
            <SignUpButton href={`${process.env.REACT_APP_FEWLSY_BUSINESS_APP_DOMAIN}/register`}>Sign Up</SignUpButton>
          </Box>
        </Box>
      </Box>
    </Container>
    <Container component="section">
      <Box width="100%" maxWidth={mdUp ? 'md' : 450} mx="auto" display="flex" flexDirection={mdUp ? 'row' : 'column'} justifyContent={mdUp ? 'center' : 'flex-start'} alignItems={mdUp ? 'center' : 'flex-start'} py={mdUp ? 4 : 2}>
        <Box mr={mdUp ? 5 : 0}>
          <img src={BusinessLeadGenerationImg} width={mdUp ? 450 : 225} height={mdUp ? 450 : 225} alt="Fewlsy Business helps your gas station to be discovered" />
        </Box>
        <Box maxWidth={450} display="flex" flexDirection="column" justifyContent={mdUp ? 'center' : 'flex-start'} alignItems="flex-start">
          <Typography component="h2" variant={mdUp ? 'h4' : 'h5'}>Lead Generation</Typography>
          <InfoDescription>Fewlsy helps buyers looking for fuel discover nearby gas stations like yours. Providing them with not just directions, but also other useful information such as services offered and active promos and offers.</InfoDescription>
        </Box>
      </Box>
    </Container>
    <Container component="section">
      <Box width="100%" maxWidth={mdUp ? 'md' : 450} mx="auto" display="flex" flexDirection={mdUp ? 'row' : 'column-reverse'} justifyContent={mdUp ? 'center' : 'flex-start'} alignItems={mdUp ? 'center' : 'flex-start'} py={mdUp ? 4 : 2}>
        <Box maxWidth={450} display="flex" flexDirection="column" justifyContent={mdUp ? 'center' : 'flex-start'} alignItems="flex-start">
          <Typography component="h2" variant={mdUp ? 'h4' : 'h5'}>Customer Experience</Typography>
          <InfoDescription>Fewlsy Business provides you with tools for better customer experiences and improving your business customer satisfaction and loyalty.</InfoDescription>
        </Box>
        <Box ml={mdUp ? 5 : 0}>
          <img src={BusinessCustomerExperienceImg} width={mdUp ? 450 : 225} height={mdUp ? 450 : 225} alt="Better customer experiences with Fewlsy Business" />
        </Box>
      </Box>
    </Container>
    <Container component="section">
      <Box width="100%" maxWidth={mdUp ? 'md' : 450} mx="auto" display="flex" flexDirection={mdUp ? 'row' : 'column'} justifyContent={mdUp ? 'center' : 'flex-start'} alignItems={mdUp ? 'center' : 'flex-start'} py={mdUp ? 4 : 2}>
        <Box mr={mdUp ? 5 : 0}>
          <img src={BusinessInsightsImg} width={mdUp ? 450 : 225} height={mdUp ? 450 : 225} alt="Discover how your Fewlsy Business is performing" />
        </Box>
        <Box maxWidth={450} display="flex" flexDirection="column" justifyContent={mdUp ? 'center' : 'flex-start'} alignItems="flex-start">
          <Typography component="h2" variant={mdUp ? 'h4' : 'h5'}>Insights</Typography>
          <InfoDescription>Discover how your Fewlsy Business is performing and make data driven decisions with the help of data collected from various Fewlsy products.</InfoDescription>
        </Box>
      </Box>
    </Container>
    <Container>
      <Box textAlign="center" py={10}>
        <Box mb={4}>
          <Typography component="p" variant={mdUp ? 'h4' : 'h5'}>Sign up for a free Fewlsy Business account today</Typography>
        </Box>
        <Box>
          <SignUpButton href={`${process.env.REACT_APP_FEWLSY_BUSINESS_APP_DOMAIN}/register`}>Sign Up</SignUpButton>
        </Box>
      </Box>
    </Container>
  </PageWrapper>
}

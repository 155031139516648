type PageHead = {
  title: string
  description: string
}

export default function usePageHead () {
  const updatePageHead = (params: PageHead) => {
    const title = document.querySelector('title')
    const description = document.querySelector('meta[name="description"]')

    if (title && params.title) {
      if (params.title.length > 60) {
        console.warn('Page Title length exceeds recommended 60 max character length')
      }
      title.innerHTML = params.title
    }

    if (description && params.description) {
      if (params.description.length > 160) {
        console.warn('Page Description length exceeds recommended 160 max character length')
      }
      description.setAttribute('content', params.description)
    }
  }

  return {
    updatePageHead
  }
}
